import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';

import FooterCta from '../components/FooterCta';
import { About } from '../components/About';
import { Building } from '../components/Building';

const AboutpageTemplate = ({
    data: {
        datoCmsAboutpage: {
            title,
            seo,
        },
        datoCmsAbout: {
            aboutAlt,
            aboutTitle,
            aboutText,
            firstKeyfactIcon,
            firstKeyfactNumber,
            firstKeyfact,
            secondKeyfactIcon,
            secondKeyfactNumber,
            secondKeyfact,
            thirdKeyfactIcon,
            thirdKeyfactNumber,
            thirdKeyfact,
            fourthKeyfactIcon,
            fourthKeyfactNumber,
            fourthKeyfact,
            aboutTeamPicture,
            aboutBarPicture1,
            aboutBarPicture2,
            aboutBuilding,
            aboutBuildingImage,
        },
        datoCmsContactpage: { 'id': contactPageId},
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
        <div className='AboutPageNavBackground'></div>
        <div>
            <About 
                aboutAlt={aboutAlt}
                aboutTitle={aboutTitle}
                aboutText={aboutText}
                firstKeyfactIcon={firstKeyfactIcon}
                firstKeyfactNumber={firstKeyfactNumber}
                firstKeyfact={firstKeyfact}
                secondKeyfactIcon={secondKeyfactIcon}
                secondKeyfactNumber={secondKeyfactNumber}
                secondKeyfact={secondKeyfact}
                thirdKeyfactIcon={thirdKeyfactIcon}
                thirdKeyfactNumber={thirdKeyfactNumber}
                thirdKeyfact={thirdKeyfact}
                fourthKeyfactIcon={fourthKeyfactIcon}
                fourthKeyfactNumber={fourthKeyfactNumber}
                fourthKeyfact={fourthKeyfact}
                aboutTeamPicture={aboutTeamPicture}
                aboutBarPicture1={aboutBarPicture1}
                aboutBarPicture2={aboutBarPicture2}
            />
             {/*
            <Building
                aboutBuilding={aboutBuilding}
                aboutBuildingImage={aboutBuildingImage}
            />
            */}
            {/* 
            <Team 
                teamAlt={teamAlt}
                teamTitle={teamTitle}
            />
            */}

            <FooterCta />
        
        </div>
    </PageWrapper>
);

export default AboutpageTemplate;

export const query = graphql`
    query AboutpageQuery($locale: String!) {
        datoCmsAboutpage(locale: { eq: $locale }) {
            locale
            title
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
        }
        datoCmsAbout(locale: { eq: $locale }) {
            locale
            aboutAlt
            aboutTitle
            aboutText
            firstKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            firstKeyfactNumber
            firstKeyfact
            secondKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            secondKeyfactNumber
            secondKeyfact
            thirdKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            thirdKeyfactNumber
            thirdKeyfact
            fourthKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            fourthKeyfactNumber
            fourthKeyfact
            aboutTeamPicture {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            aboutBarPicture1 {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            aboutBarPicture2 {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            aboutBuilding
            aboutBuildingImage {
                gatsbyImageData(
                    placeholder: BLURRED
                )
            }
        }
        datoCmsContactpage(locale: { eq: $locale }) {
            id: originalId
        }
    }
`;